<script lang="ts" setup>
import type { HomeJackpot } from "@/types";

const props = defineProps<{
	jackpots: HomeJackpot;
	lastBigWin?: number;
	isSweepStakes: boolean;
}>();

const { t } = useT();
const { open } = useNlcModals();
const { isDesktop } = useDevice();

const jackpotsValues = computed(() => props.jackpots?.[props.isSweepStakes ? "USD" : "TRN"]?.values || []);

const allWin = computed(() => {
	const factor = props.isSweepStakes ? 1 : 100;
	return Array.isArray(jackpotsValues.value)
		? jackpotsValues.value?.reduce((acc, num) => acc + Number(num.amount) * factor, 0)
		: 0;
});

const ratings = computed(() => [
	{
		title: t("Games"),
		val: t("90+"),
		icon: "games"
	},
	{
		title: t("Coins every day"),
		val: t("100,000"),
		icon: "coins"
	},
	{
		title: t("Jackpot"),
		val: numberFormat(+allWin.value.toFixed(0)),
		icon: "jackpot"
	},
	{
		title: t("Last big win"),
		val: numberFormat(props.lastBigWin || 0),
		icon: "win"
	}
]);

const handleClick = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: "benefits",
		location: "home_page"
	});
	open("LazyOModalSignup");
};
</script>

<template>
	<div>
		<MHomeSectionHeader :title="t('Why Choose Us?')" icon="24/crown" />
		<div class="ratings">
			<AAnimationHoverSlide
				v-for="rating in ratings"
				:key="rating.title"
				class="ratings__item"
				:active="isDesktop"
				@click="handleClick"
			>
				<div class="ratings__content">
					<AText class="ratings__val" variant="taipei" :modifiers="['bold']">
						{{ rating.val }}
					</AText>

					<AText class="ratings__title">
						{{ rating.title }}
					</AText>
				</div>

				<NuxtImg
					format="webp"
					:src="`/nuxt-img/ratings/${rating.icon}@2x.png`"
					loading="lazy"
					:alt="rating.title"
					class="ratings__icon"
				/>
			</AAnimationHoverSlide>

			<AButton class="mobile-button" variant="primary" size="lg" :modifiers="['wide']" @click="handleClick">
				<AText variant="toledo" :modifiers="['uppercase', 'bold']">{{ t("Get started") }}</AText>
			</AButton>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.ratings {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	gap: 10px;

	@include media-breakpoint-down(lg) {
		flex-wrap: wrap;
	}

	&__item {
		width: calc(25% - 12px);
		height: 124px;
		background: var(--custom-14);
		box-shadow: 0 4px 10px var(--custom-rgb-24);
		border-radius: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 16px;
		cursor: pointer;

		@include media-breakpoint-down(lg) {
			width: calc(50% - 5px);
			padding: 16px;
			height: auto;
			align-items: center;
		}

		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
	}
	&__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 8px;
	}
	&__val {
		@include media-breakpoint-down(md) {
			text-align: center;
		}
	}
	&__title {
		@include media-breakpoint-down(lg) {
			margin-top: 8px;
		}
		@include media-breakpoint-down(md) {
			text-align: center;
			margin-top: 4px;
		}
	}
	&__icon {
		width: 96px;
		height: 96px;

		@include media-breakpoint-down(md) {
			width: 64px;
			height: 64px;
		}
	}
}
@include media-breakpoint-up(md) {
	.mobile-button {
		display: none;
	}
}
</style>
